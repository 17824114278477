import AccountProcessStepCode from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-code/1.0.0/index.vue'

export default {
  data() {
    return {
      failDialogVisible: false
    }
  },
  components: {
    AccountProcessStepCode
  },
  mounted(){
    let formBox = this.$route.query.form;
    let params = {
      form: {
        idNo: this.$route.query.idNo ? this.$route.query.idNo : '',
        name: this.$route.query.name ? this.$route.query.name : '',
        phoneNo: this.$route.query.phoneNo ? this.$route.query.phoneNo : '',
      }
    };
    this.$refs['accountProcessStepRegister'].init(params);
  },
  methods: {
    registerResult() {
      this.$router.replace({
        name: 'admin-my-information',
      })
    },
    failSubmit() {
      this.failDialogVisible = false;
      this.back();
    },
    beforeBack() {
      this.failDialogVisible = true;
    },
    back() {
      this.$router.go(-2);
    },
  },
};
